import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import H2 from "../components/Objects/H2"
import H3 from "../components/Objects/H3"
import Seo from "../components/seo"

const SustainabilityPage = () => {
  const prosElectricity = [
    "Monitor energy consumption.",
    "Identify opportunities to save energy",
    "Estimate how much energy each opportunity could save.",
    "Find & quantify routine energy waste",
    "Increase energy savings ",
  ]
  const prosWater = [
    "Detect minor and major leaks as they occur through timely alerts",
    "Achieve accurate billing/collection with immediate savings",
    "Provide insights to users creating a more environmentally-aware end-consumer.",
  ]
  return (
    <>
      <Seo title="Impact" />
      <main>
        <section className="relative w-full   py-12 h-[60vh] md:h-[65vh]   flex items-center ">
          <h1 className="text-white mb-12 z-50 text-2xl md:text-3xl lg:text-7xl w-10/12 mx-auto flex">
            Saving the planet, one <br />
            metering point at a time.
          </h1>
          <StaticImage
            src="../images/impact.jpg"
            placeholder="blurred"
            alt="Electricity"
            className="!absolute inset-0 h-full w-full object-cover"
          />
        </section>

        <section>
          <div className="grid bg-[#EEF5FD]">
            <div className=" py-28 w-10/12 order-2 mx-auto  text-secondary">
              <H2 className=" mb-12  tracking-tight ">
                Pylon is committed to fostering sustainable development.
              </H2>
              <p className="text-3xl w-10/12">
                Our ultimate goal is to help save the planet by killing
                inefficiencies and tackling pain points in the electricity and
                water sectors. By deploying our solutions, utilities can reduce
                their carbon footprint by up to 25% and water losses by up to
                20%.
              </p>
            </div>
          </div>
        </section>
        <section className=" py-14 bg-[#F9FAFB]  ">
          <div className="w-11/12 lg:w-10/12 mx-auto pt-4 border-t border-[#002369]">
            {/* <p className="font-medium text-xl text-secondary">Electricity</p> */}
            <div className="py-20 lg:py-40">
              <H3 className="text-secondary  mb-4 ">Electricity</H3>
              <p className=" md:text-xl text-secondary opacity-70 mb-16  mx-auto">
                Our comprehensive demand management suite is the key to carbon
                offsetting through the two Rs - Reduction & Redistribution. The
                solution can help your utility:
              </p>
              <div className=" grid lg:grid-cols-2 gap-20">
                <div>
                  <ul className="flex flex-col gap-6">
                    {prosElectricity &&
                      prosElectricity.map((text, index) => {
                        return (
                          <li className="flex gap-3  " key={index++}>
                            <div className="  border w-3 h-3 mt-2 border-secondary rounded-full flex-shrink-0 flex-none"></div>
                            <p className="text-secondary md:text-xl ">{text}</p>
                          </li>
                        )
                      })}
                  </ul>
                  <p className="text-secondary md:text-xl mt-6">
                    Thereby offsetting annual carbon emissions by up to
                    1tCO2/metering point deployed.
                  </p>
                </div>
                <div className=" row-start-1 row-end-2 lg:row-auto">
                  <StaticImage
                    src="../images/electricity.jpg"
                    placeholder="blurred"
                    alt="Electricity"
                    class="w-full h-full object-cover"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className=" py-14 bg-[#F9FAFB]  ">
          <div className="w-11/12 lg:w-10/12 mx-auto pt-4 border-t border-[#002369]">
            {/* <p className="font-medium text-xl text-secondary">Water</p> */}
            <div className="py-20 lg:py-40">
              <H3 className="text-secondary  mb-4 ">Water</H3>
              <p className=" md:text-xl text-secondary opacity-70 mb-16  mx-auto">
                In developing countries, about 45 million cubic meters of clean
                water are lost daily through water leakage in the distribution
                networks—enough to serve nearly 200 million people. Pylon can
                tackle your utility’s water challenges and allow you to
              </p>
              <div className=" grid lg:grid-cols-2 gap-20">
                <div className=" row-start-1 row-end-2 lg:row-auto">
                  <StaticImage
                    src="../images/water.png"
                    placeholder="blurred"
                    alt="Electricity"
                    class="w-full h-full object-cover"
                    quality={100}
                  />
                </div>
                <div>
                  <ul className="flex flex-col gap-6">
                    {prosWater &&
                      prosWater.map((text, index) => {
                        return (
                          <li className="flex gap-3  " key={index++}>
                            <div className="  border w-3 h-3 mt-2 border-secondary rounded-full flex-shrink-0 flex-none"></div>
                            <p className="text-secondary md:text-xl ">{text}</p>
                          </li>
                        )
                      })}
                  </ul>
                  <p className="text-secondary md:text-xl mt-6">
                    Our solution’s impact extends deep into the reduction of
                    non-revenue water by effectively reducing physical water
                    losses as well as slashing commercial losses.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      {/* <EmailUs highlighted /> */}
    </>
  )
}
export default SustainabilityPage
const CheckIcon = (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="10.001" cy="9.99902" r="10" fill="currentColor" />
    <g clipPath="url(#clip0_402_5361)">
      <path
        d="M6.00098 9.66496L8.66764 12.3316L14.001 6.99829"
        stroke="white"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_402_5361">
        <rect
          width="11"
          height="8"
          fill="white"
          transform="translate(4.00098 5.99902)"
        />
      </clipPath>
    </defs>
  </svg>
)
