import React from "react"
import cn from "classnames"

const H3 = ({ className, children }) => {
  const headerClasses = cn("text-2xl md:text-3xl lg:text-5xl", className)

  return <h3 className={headerClasses}>{children}</h3>
}

export default H3
